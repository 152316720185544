<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: center; flex-wrap: wrap;">
                <p class="mon-bold f-s45 l-spacing0 mb-0">
                  {{ "Precios de fletes" }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                <v-btn @click="openBulkLoad()" :disabled="tableData.length == 0" depressed class="gradient-button mt-3" color="transparent">
                  {{ "Actualizar costos" }}
                </v-btn>
              </div>
            </div>
            <v-card class="mx-3 mt-2 pb-7">
              <v-data-table
                :headers="headers"
                :disable-sort="true"
                :loading="loading"
                loading-text="Loading... Please wait"
                hide-default-footer
                :items="tableData"
              >
                <template v-slot:item.dPrice="{ item }">
                  <span> {{ "$ " + formatMoney(item.dPrice) + " MXN" }} </span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn @click="editFreightPrice(item)" icon color="#000000">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>

            <div class="d-flex justify-center text-center mt-3 pb-2">
              <v-pagination color="yellow darken-1" v-model="iCurrentPage" :length="iNumPages"></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogEditFreightPrice" persistent max-width="1200px">
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Editar precio de flete</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="dialogEditFreightPrice = false">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" v-on:submit.prevent lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.EnterpriseName"
                    prepend-icon="mdi-account"
                    label="Proveedor"
                    maxlength="50"
                    disabled
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sVendorWarehouse"
                    prepend-icon="mdi-alpha-p"
                    label="Almacen del proveedor"
                    disabled
                    maxlength="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sInOut"
                    prepend-icon="mdi-alpha-m"
                    label="In & out"
                    disabled
                    maxlength="50"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dPrice"
                    type="number"
                    prepend-icon="mdi-alpha-m"
                    label="Precio"
                    maxlength="50"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUpdatePrices" persistent max-width="1200px">
      <v-stepper v-model="e1">
        <v-stepper-header style="display: flex; flex-direction: column; flex-wrap: nowrap; height: fit-content;">
          <div style="text-align: end;">
            <v-btn class="pa-0" text @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <div class="d-flex">
            <v-stepper-step @click="e1 = 1" :complete="e1 == 1" step="1">
              Exportar Precios
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step @click="e1 = 2" :complete="e1 == 2" step="2">
              Importar Precios
            </v-stepper-step>
          </div>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card>
              <v-card-title>
                Costo de fletes
                <v-spacer></v-spacer>
                <div style="display: flex; gap: 20px;">
                  <v-text-field
                    v-model="search"
                    class="pt-0 mt-0"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-btn @click="exportToXLSX()" color="green" :disabled="selected.length == 0" class="white--text" elevation="0" small>
                    Descargar <v-icon class="ml-2">mdi-microsoft-excel</v-icon></v-btn
                  >
                </div>
              </v-card-title>
              <v-data-table
                fixed-header
                height="300px"
                show-select
                v-model="selected"
                item-key="sFreightPricesId"
                :loading="loading"
                :headers="headersExcel"
                :items="excelTableData"
                :search="search"
                :disable-sort="true"
              >
                <template v-slot:item.dPrice="{ item }">
                  <span> {{ "$ " + formatMoney(item.dPrice) + " MXN" }} </span>
                </template>
              </v-data-table>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div style="display: flex; align-items: flex-end; justify-content: space-between;">
              <div style="display: flex; align-items: flex-end;">
                <v-file-input
                  v-model="file"
                  @change="handleFileUpload"
                  hide-details
                  style="min-width: 300px;"
                  multiple
                  label="Subir archivo"
                ></v-file-input>
              </div>
              <v-btn @click="editArticle()" :disabled="results.length == 0" depressed class="gradient-button" color="transparent">
                {{ "Actualizar" }}
              </v-btn>
            </div>
            <div
              class="text-center"
              style="display: flex; flex-direction: column; justifyContent: center; alignItems: center; height: 300px;"
              v-if="results.length == 0"
            >
              <img class="empty-image" src="@/assets/gif/gifEmpty.gif" alt="" />
              <p class="empty-text">
                {{ texts.emptyText }}
              </p>
            </div>
            <div v-else>
              <v-card class="mt-4">
                <v-data-table
                  fixed-header
                  height="300px"
                  item-key="sFreightPricesId"
                  :headers="headersExcelResult"
                  :items="results"
                  :loading="loading"
                  :search="search"
                  :disable-sort="true"
                >
                  <template v-slot:item.dPrice="{ item }">
                    <span> {{ "$ " + formatMoney(item.dPrice) + " MXN" }} </span>
                  </template>
                </v-data-table>
              </v-card>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay style="z-index: 99999999;" :value="overlay">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      overlay: false,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      aSubCategory: [],
      loading: false,
      //
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      texts: "",
      e1: 1,
      dialogEditFreightPrice: false,
      dialogUpdatePrices: false,
      dialogListPrices: false,
      selected: [],
      selectedArtcilesToList: [],
      exchangeRate: "",
      flete: "15,000 MXN",
      aduana: "260",
      inOut: "$ 15 p/pallet",
      takeRate: "2.5 %",
      snack: false,
      snackColor: "",
      snackText: "",
      data: [],
      results: [],
      headers: [
        { text: "Proveedor", value: "EnterpriseName", align: "center" },
        { text: "Almacen", value: "sVendorWarehouse", align: "center" },
        { text: "In & Out", value: "sInOut", align: "center" },
        { text: "Costo", value: "dPrice", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersExcel: [
        { text: "Proveedor", value: "EnterpriseName", align: "center" },
        { text: "Almacen", value: "sVendorWarehouse", align: "center" },
        { text: "In & Out", value: "sInOut", align: "center" },
        { text: "Costo", value: "dPrice", align: "center" },
      ],
      headersExcelResult: [
        { text: "Proveedor", value: "EnterpriseName", align: "center" },
        { text: "Almacen", value: "sVendorWarehouse", align: "center" },
        { text: "In & Out", value: "sInOut", align: "center" },
        { text: "Costo", value: "dPrice", align: "center" },
      ],
      forms: {
        update: {},
      },
      selectedCustomer: null,
      selectedWarehouse: null,
      selectedPayment: null,
      items: [],
      tableData: [],
      excelTableData: [],
      customers: [],
      reportTypeGeneral: "",
      file: null,
      sheetsGeneral: [],
      jsonData: null,
      dataWithHeaders: null,
      today: "",
      people: [{ name: "Perlag" }, { name: "Taylors Boys" }],
      warehouse: [{ name: "McAllen" }],
      paymentMethods: [{ name: "Credito Proplat" }, { name: "Transferencia Bancaria" }, { name: "Cheque" }],
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.fillTable();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    extractValue(text) {
      const value = text.replace(/[^\d.]/g, ""); // Elimina cualquier carácter que no sea dígito o punto
      return parseFloat(value);
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    detailItem(item) {
      this.$router.push("/admin/article-detail/" + item.sProductId);
    },
    openBulkLoad() {
      this.dialogUpdatePrices = true;
      this.fillTableExcel();
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    fillTable() {
      this.tableData = [];
      this.loading = true;

      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
        },
      })
        .then((response) => {
          this.tableData = response.data.results.map((item) => ({
            ...item,
            dPrice: item.dPrice ? parseFloat(item.dPrice) : 0,
          }));

          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    editFreightPrice(item) {
      this.forms.update = JSON.parse(JSON.stringify(item));
      this.dialogEditFreightPrice = true;
    },
    save() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = [this.forms.update];

        const params = data;
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/freights`, params, config)
          .then((res) => {
            this.fillTable();
            this.cancel();
            this.loading = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    fillTableExcel() {
      this.excelTableData = [];
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.excelTableData = response.data.results;
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    exportToXLSX() {
      if (this.selected.length > 0) {
        let data = this.selected.map((item) => {
          return {
            sFreightPricesId: item.sFreightPricesId ? item.sFreightPricesId : "",
            sBorderWarehouseId: item.sBorderWarehouseId ? item.sBorderWarehouseId : "",
            sVendorWarehouseId: item.sVendorWarehouseId ? item.sVendorWarehouseId : "",
            Proveedor: item.EnterpriseName ? item.EnterpriseName : "",
            Almacen: item.sVendorWarehouse ? item.sVendorWarehouse : "",
            "In & Out": item.sInOut ? item.sInOut : "",
            Costo: item.dPrice ? this.formatMoney(item.dPrice) : this.formatMoney(0),
          };
        });

        let nameXLSX = "Costo_de_fletes_" + moment().format("YYYY-MM-DD") + ".xlsx";
        // Crea una hoja de cálculo y agrega los datos
        const worksheet = XLSX.utils.json_to_sheet(data);
        // Crea un libro de trabajo y agrega la hoja de cálculo
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");
        // Convierte el libro de trabajo a un archivo binario
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        // Crea un Blob a partir del archivo binario
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        // Descarga el archivo utilizando FileSaver.js
        saveAs(blob, nameXLSX);
      }
    },
    async handleFileUpload(event) {
      const file = event[0];
      const reader = new FileReader();
      this.jsonData = [];
      if (file) {
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          // Guarda el JSON en los datos del componente
          this.jsonData = json;
          const headers = [
            "sFreightPricesId",
            "sBorderWarehouseId",
            "sVendorWarehouseId",
            "EnterpriseName",
            "sVendorWarehouse",
            "sInOut",
            "dPrice",
          ];
          this.jsonData = this.jsonData.slice(1); // Resto de elementos del JSON contienen los datos
          this.jsonData = this.jsonData.map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          const idsConDiferencias = [];

          this.excelTableData.forEach((objeto) => {
            const sFreightPricesId = objeto.sFreightPricesId;

            const objetoSegundoArray = this.jsonData.find((item) => item.sFreightPricesId === sFreightPricesId);

            if (objetoSegundoArray) {
              objeto.dPrice = objeto.dPrice ? this.formatMoney(objeto.dPrice) : this.formatMoney(0);

              const tieneDiferencias = objeto.dPrice !== objetoSegundoArray.dPrice;
              if (tieneDiferencias) {
                idsConDiferencias.push(objetoSegundoArray);
              }
            }
          });
          this.results = idsConDiferencias;
        };
        reader.readAsArrayBuffer(file);
      } else {
        this.results = [];
      }
    },
    editArticle() {
      this.loading = true;
      const params = this.results;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      console.log(params);
      DB.post(`${URI}/api/v1/${this.selectLanguage}/freights`, params, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.fillTable();
          this.cancel();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.cancel();
        });
    },
    setDates(date) {
      const fechaConvertida = moment(date, "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
      return fechaConvertida;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    cancel() {
      this.dialogUpdatePrices = false;
      this.dialogEditFreightPrice = false;
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
      this.loading = false;
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.fillTable();
    },
    sSubcategoryId: function() {
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
      this.fillTable();
      this.fillTableExcel();
    },
  },
};
</script>
<style scoped>
.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}

.titleText {
  width: 100%;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.empty-image {
  width: 200px;
}
</style>
